import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  Button,
  IconButton,
  Typography,
} from '@mui/material';
import { ApplicationDocumentFragment } from '../../../../../../generated/graphql';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { QrMultipleSigning } from '../../../../../QrSigning';
import CloseIcon from '@mui/icons-material/Close';

export const StageActionButtonSigningDialog = ({
  open,
  handleClose,
  applicationDocuments,
}: {
  open: boolean;
  handleClose: () => void;
  applicationId: string;
  applicationDocuments: ApplicationDocumentFragment[];
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="xs"
      sx={{
        '& .MuiDialog-paper': {
          width: '100%',
          margin: 2,
        },
      }}
    >
      <DialogTitle borderBottom="1px solid rgba(0, 0, 0, 0.12)">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6">Подписание</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ p: 2 }}>
        <Stack spacing={1} my={2}>
          {applicationDocuments.map((applicationDocument) => (
            <Button
              key={applicationDocument.id}
              variant="outlined"
              href={applicationDocument.signedURL}
              target="_blank"
              endIcon={<OpenInNewIcon />}
              size="large"
            >
              {applicationDocument.serviceDocument.name}
            </Button>
          ))}
        </Stack>
        <QrMultipleSigning
          applicationDocuments={applicationDocuments.map(({ id }, index) => ({
            id,
            index,
          }))}
          handleClose={handleClose}
        />
      </DialogContent>
    </Dialog>
  );
};
