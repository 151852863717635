import { useForm } from 'react-hook-form';
import { Box, Grid } from '@mui/material';
import { Input } from '../../../../../Input';
import { InputType } from '../../../../../../generated/graphql';

export const ApplicationDocumentFormNew = ({
  loading,
  handleSubmit,
}: {
  loading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSubmit: (data: any) => void;
}) => {
  const methods = useForm();

  const onSubmit = (data: unknown) => {
    handleSubmit(data);
  };

  return (
    <Box>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        noValidate
        id="APPLICATION_DOCUMENT_NEW"
      >
        <Grid container spacing={2}>
          <Input
            input={{
              type: InputType.File,
              name: 'file',
              label: 'Квитанция об оплате',
              position: 0,
              rules: {
                required: {
                  value: true,
                  message: 'Обязательное поле',
                },
              },
              params: {
                defaultValue: [],
              },
            }}
            {...methods}
            disabled={loading}
          />
        </Grid>
      </form>
    </Box>
  );
};
