import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  FormControl,
  TextField,
  FormHelperText,
  Box,
  Button,
  Alert,
  AlertTitle,
  useMediaQuery,
  useTheme,
  IconButton,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
  InputType,
  usePhoneNumberSendCodeMutation,
  usePhoneNumberVerifyMutation,
} from '../../../../generated/graphql';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Input } from '../../../../common';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';

interface Inputs {
  phoneNumber: string;
  code: string;
}

export const ProfilePhoneNumberDialog = ({
  onClose,
  phoneNumber,
}: {
  onClose: () => void;
  phoneNumber: string;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [edit, setEdit] = useState(false);

  const handleEdit = () => {
    methods.setValue('phoneNumber', '+7');
    setEdit(true);
  };

  const handleShow = () => {
    methods.setValue('phoneNumber', phoneNumber);
    setEdit(false);
  };

  const [sendCode, { loading: loadingVerify, data: sendCodeData }] =
    usePhoneNumberSendCodeMutation();
  const [verify, { loading: loadingConfirm, data: verifyData }] =
    usePhoneNumberVerifyMutation({
      onCompleted(data) {
        if (data.studentPhoneNumberVerify) {
          handleShow();
        }
      },
    });

  const methods = useForm<Inputs>({
    defaultValues: {
      phoneNumber,
      code: '',
    },
  });

  useEffect(() => {
    if (verifyData?.studentPhoneNumberVerify === null) {
      methods.setError('code', {
        message: 'Неверный код',
      });
    }
  }, [verifyData?.studentPhoneNumberVerify]);

  const onSubmit: SubmitHandler<Inputs> = ({ phoneNumber, code }) => {
    if (sendCodeData?.studentPhoneNumberSendCode) {
      verify({
        variables: {
          phoneNumber,
          code,
        },
      });
      return;
    }

    sendCode({
      variables: {
        phoneNumber,
      },
    });
  };

  return (
    <Dialog
      open
      onClose={onClose}
      fullScreen={isMobile}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle borderBottom="1px solid rgba(0, 0, 0, 0.12)">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6">Номер телефона</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ px: 2 }}>
        <Alert
          severity="info"
          sx={{
            my: 2,
          }}
        >
          <AlertTitle>Номер для звонков</AlertTitle>
          По данному номеру сотрудники университета смогут связаться с Вами для
          уточнения данных
        </Alert>
        <Box
          component="form"
          id="PHONE_NUMBER_FORM"
          onSubmit={methods.handleSubmit(onSubmit)}
          py={1}
          sx={{
            minHeight: '400px',
          }}
        >
          <Stack spacing={2} mb={2}>
            <Input
              input={{
                type: InputType.PhoneNumber,
                name: 'phoneNumber',
                label: 'Номер телефона',
                rules: {
                  required: {
                    value: true,
                    message: 'Обязательное поле',
                  },
                  minLength: {
                    value: 11,
                    message: 'Номер телефона не должен быть меньше 10 цифр',
                  },
                  maxLength: {
                    value: 16,
                    message: 'Номер телефона не должен превышать 15 цифр',
                  },
                },
                position: 0,
                params: {
                  defaultValue: '+7',
                  autoFocus: true,
                },
              }}
              {...methods}
              show={!edit}
            />

            {edit && sendCodeData?.studentPhoneNumberSendCode && (
              <Controller
                name="code"
                control={methods.control}
                rules={{
                  required: {
                    value: true,
                    message: 'Обязательное поле',
                  },
                  minLength: {
                    value: 6,
                    message: 'Код состоит из 6 цифр',
                  },
                  maxLength: {
                    value: 6,
                    message: 'Код состоит из 6 цифр',
                  },
                }}
                render={({ field, fieldState }) => (
                  <FormControl error={!!fieldState.error} fullWidth>
                    <TextField
                      {...field}
                      label="Код"
                      type="number"
                      autoFocus
                      placeholder="Введите 6-значный код"
                      error={!!fieldState.error}
                    />
                    <FormHelperText>{fieldState.error?.message}</FormHelperText>
                  </FormControl>
                )}
              />
            )}
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        {edit ? (
          <Stack
            sx={{
              width: isMobile ? '100%' : 'auto',
            }}
            direction={isMobile ? 'column-reverse' : 'row'}
            spacing={1}
          >
            <Button
              onClick={handleShow}
              variant="outlined"
              fullWidth={isMobile}
            >
              Назад
            </Button>
            <LoadingButton
              type="submit"
              form="PHONE_NUMBER_FORM"
              variant="contained"
              fullWidth={isMobile}
              loading={loadingVerify || loadingConfirm}
            >
              {sendCodeData?.studentPhoneNumberSendCode
                ? 'Подтвердить'
                : 'Отправить код'}
            </LoadingButton>
          </Stack>
        ) : (
          <Stack
            sx={{
              width: isMobile ? '100%' : 'auto',
            }}
            direction={isMobile ? 'column-reverse' : 'row'}
            spacing={1}
          >
            <Button variant="outlined" onClick={onClose} fullWidth={isMobile}>
              Закрыть
            </Button>
            <Button
              variant="contained"
              onClick={handleEdit}
              fullWidth={isMobile}
            >
              Изменить
            </Button>
          </Stack>
        )}
      </DialogActions>
    </Dialog>
  );
};
