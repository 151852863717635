import { Typography, Box } from '@mui/material';
import { ApplicationDataFragment } from '../../../../../../generated/graphql';

export const Instructions = ({
  applicationData: {
    student: {
      data: { faculty, courseYear },
    },
    user: { iin, fullName },
  },
}: {
  applicationData: ApplicationDataFragment;
}) => (
  <Box>
    <Typography variant="h6">Инструкция по оплате через KASPI банк</Typography>
    <ol
      style={{
        paddingLeft: '20px',
      }}
    >
      <li>
        <Typography>Открыть приложение Kaspi.kz</Typography>
      </li>
      <li>
        <Typography>Перейти в Платежи</Typography>
      </li>
      <li>
        <Typography>Выбрать «Образование»</Typography>
      </li>
      <li>
        <Typography>Выбрать «Вузы и колледжи»</Typography>
      </li>
      <li>
        <Typography>Данные</Typography>
      </li>
      <ul
        style={{
          paddingLeft: '20px',
        }}
      >
        <li>
          <Typography>
            Город: <b>Караганда</b>
          </Typography>
        </li>
        <li>
          <Typography>
            Название учебного заведения:{' '}
            <b>Карагандинский экономический университет Казпотребсоюза</b> (г.
            Караганда, ул. Академическая, 9)
          </Typography>
        </li>
        <li>
          <Typography>
            Факультет: <b>{faculty.shortName} за общежитие</b>
          </Typography>
        </li>
        <li>
          <Typography>
            Курс: <b>{courseYear}</b>
          </Typography>
        </li>
        <li>
          <Typography>
            ФИО студента: <b>{fullName}</b>
          </Typography>
        </li>
        <li>
          <Typography>
            ИИН студента: <b>{iin}</b>
          </Typography>
        </li>
        <li>
          <Typography>
            Сумма: <b>51 000 ₸</b> (за 3 месяца)
          </Typography>
        </li>
      </ul>
    </ol>
  </Box>
);
