import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  useTheme,
  useMediaQuery,
  Typography,
  IconButton,
} from '@mui/material';
import {
  StudentDocumentFragment,
  useUpdateStudentDocumentMutation,
  useGetSignedUrlsForFileUploadLazyQuery,
} from '../../generated/graphql';
import { LoadingButton } from '@mui/lab';
import { StudentDocumentFormEdit } from './StudentDocumentFormEdit';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';

export const StudentDocumentDialogEdit = ({
  studentDocument,
  handleShow,
}: {
  studentDocument: StudentDocumentFragment;
  handleShow: () => void;
}) => {
  const [uploading, setUploading] = useState<boolean>(false);
  const [updateStudentDocument, { loading }] =
    useUpdateStudentDocumentMutation();
  const [getSignedUrls] = useGetSignedUrlsForFileUploadLazyQuery();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleUpdateDocument = async (formData: any) => {
    const { files, ...form } = formData;

    setUploading(true);

    const fileList = files as FileList;

    const { data } = await getSignedUrls({
      variables: {
        documentCategory: studentDocument.document.category,
        fileNames: Array.from(fileList).map((file) => file.name),
      },
    });

    if (!data) {
      throw Error('Something went wrong');
    }

    const payloadFiles = await Promise.all(
      data.studentGetSignedUrlsForFileUpload.map(
        async ({ signedUrl, key }, index) => {
          await fetch(signedUrl, {
            method: 'PUT',
            headers: {
              'Content-Type': fileList[index].type,
            },
            body: fileList[index],
          });

          return {
            key,
            name: fileList[index].name,
            type: fileList[index].type,
          };
        },
      ),
    ).finally(() => {
      setUploading(false);
    });

    updateStudentDocument({
      variables: {
        documentCategory: studentDocument.document.category,
        data: form,
        files: payloadFiles,
      },
    }).then(() => {
      handleShow();
    });
  };

  return (
    <Dialog open fullWidth maxWidth="md" fullScreen={isMobile}>
      <DialogTitle borderBottom="1px solid rgba(0, 0, 0, 0.12)">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6">{studentDocument.document.name}</Typography>
          <IconButton onClick={handleShow}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ p: 2 }}>
        <StudentDocumentFormEdit
          data={studentDocument.data}
          inputs={studentDocument.document.inputs}
          loading={uploading || loading}
          handleSubmit={handleUpdateDocument}
        />
      </DialogContent>
      <DialogActions>
        <Stack
          direction={{
            xs: 'column-reverse',
            md: 'row',
          }}
          width={{
            xs: '100%',
            md: 'auto',
          }}
          spacing={1}
        >
          <Button
            fullWidth={isMobile}
            onClick={handleShow}
            variant="outlined"
            disabled={uploading || loading}
          >
            Назад
          </Button>
          <LoadingButton
            type="submit"
            fullWidth={isMobile}
            variant="contained"
            form="STUDENT_DOCUMENT_EDIT"
            loading={uploading || loading}
          >
            Сохранить
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
