import {
  FormLabel,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import {
  buildCountryData,
  CountryIso2,
  defaultCountries,
  FlagImage,
  parseCountry,
  usePhoneInput,
} from 'react-international-phone';
import countries from 'i18n-iso-countries';
import ru from 'i18n-iso-countries/langs/ru.json';
import { Controller } from 'react-hook-form';
import 'react-international-phone/style.css';

import { InputProps } from '../../InputController';

countries.registerLocale(ru);

const lowercasedCountries = defaultCountries
  .map((country) => {
    const parsedCountry = parseCountry(country);

    parsedCountry.name =
      countries.getName(parsedCountry.iso2.toUpperCase(), 'ru') || '';

    return buildCountryData(parsedCountry);
  })
  .sort((a, b) => parseCountry(a).name.localeCompare(parseCountry(b).name));

export const InputPhoneNumber = ({
  input: {
    label,
    name,
    rules,
    params: { defaultValue, autoFocus },
  },
  show,
  disabled,
  control,
  getValues,
  setValue,
}: InputProps) => {
  const { inputValue, inputRef, handlePhoneValueChange, country, setCountry } =
    usePhoneInput({
      defaultCountry: 'kz',
      value: getValues(name),
      countries: lowercasedCountries,
      onChange: (data) => {
        setValue(name, data.phone);
      },
    });

  if (show) {
    return (
      <>
        <FormLabel component="legend">{label}</FormLabel>
        <div>{getValues(name)}</div>
      </>
    );
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={Object.fromEntries(
        Object.entries(rules).filter(([, value]) => value !== null),
      )}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          autoFocus={!!autoFocus}
          inputRef={inputRef}
          fullWidth
          error={!!fieldState.error}
          label={label}
          required={!!rules.required}
          helperText={fieldState.error?.message}
          disabled={disabled}
          InputLabelProps={{
            required: rules.required?.value,
          }}
          placeholder={label}
          value={inputValue}
          onChange={handlePhoneValueChange}
          type="tel"
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                style={{ marginRight: '2px', marginLeft: '-8px' }}
              >
                <Select
                  MenuProps={{
                    style: {
                      height: '300px',
                      width: '360px',
                      top: '10px',
                      left: '-34px',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  }}
                  sx={{
                    width: 'max-content',
                    // Remove default outline (display only on focus)
                    fieldset: {
                      display: 'none',
                    },
                    '&.Mui-focused:has(div[aria-expanded="false"])': {
                      fieldset: {
                        display: 'block',
                      },
                    },
                    // Update default spacing
                    '.MuiSelect-select': {
                      padding: '8px',
                      paddingRight: '24px !important',
                    },
                    svg: {
                      right: 0,
                    },
                  }}
                  value={country.iso2}
                  onChange={(e) => setCountry(e.target.value as CountryIso2)}
                  renderValue={(value) => (
                    <FlagImage iso2={value} style={{ display: 'flex' }} />
                  )}
                >
                  {lowercasedCountries.map((c) => {
                    const country = parseCountry(c);
                    return (
                      <MenuItem key={country.iso2} value={country.iso2}>
                        <FlagImage
                          iso2={country.iso2}
                          style={{ marginRight: '8px' }}
                        />
                        <Typography marginRight="8px">
                          {country.name}
                        </Typography>
                        <Typography color="gray">
                          +{country.dialCode}
                        </Typography>
                      </MenuItem>
                    );
                  })}
                </Select>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};
