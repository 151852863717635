import { Stack, Typography, Card, CardContent, Box, Grid } from '@mui/material';
import { ApplicationDocumentFragment, UserRole } from '../../generated/graphql';
import { Link } from 'react-router-dom';

export const ApplicationDocuments = ({
  applicationDocuments,
}: {
  applicationDocuments: ApplicationDocumentFragment[];
}) => {
  if (applicationDocuments.length === 0) {
    return null;
  }

  return applicationDocuments.map((applicationDocument) => (
    <Card key={applicationDocument.id} variant="outlined">
      <CardContent>
        <Stack spacing={1}>
          <Box>
            <Grid container>
              <Grid item xs>
                <Typography fontWeight={500}>
                  {applicationDocument.serviceDocument.name}
                </Typography>
              </Grid>
              {applicationDocument.referenceNumber && (
                <Grid item xs="auto">
                  <Typography color="gray">
                    {applicationDocument.referenceNumber}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Box>

          {applicationDocument.signers.length > 0 && (
            <Box>
              <Typography>Подписи</Typography>
              {applicationDocument.signers.map(({ user, signed }) => (
                <Typography key={user.id}>
                  {`${
                    user.role === UserRole.Student
                      ? 'Студент'
                      : `${user.officer?.position.name} (${user.lastName} ${user.firstName[0]} ${user.middleName[0]})`
                  }: ${signed ? 'Подписано' : 'В ожидании'}`}
                </Typography>
              ))}
            </Box>
          )}

          {applicationDocument.fileCardUrl && (
            <Link to={applicationDocument.fileCardUrl} target="_blank">
              Карточка электронного документа
            </Link>
          )}

          <Link to={applicationDocument.signedURL} target="_blank">
            Оригинальный документ
          </Link>
        </Stack>
      </CardContent>
    </Card>
  ));
};
