import FormLabel from '@mui/material/FormLabel';
import { Controller } from 'react-hook-form';
import { InputProps } from '../../InputController';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';

export const InputSelect = ({
  input: {
    label,
    name,
    rules,
    params: { defaultValue, options },
  },
  show,
  disabled,
  control,
  getValues,
}: InputProps) => {
  if (show) {
    return (
      <>
        <FormLabel component="legend">{label}</FormLabel>
        <div>
          {options?.find((option) => getValues(name) === option.value)?.label}
        </div>
      </>
    );
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={Object.fromEntries(
        Object.entries(rules).filter(([, value]) => value !== null),
      )}
      defaultValue={defaultValue}
      disabled={disabled}
      render={({ field, fieldState }) => (
        <FormControl
          fullWidth
          required={rules.required?.value}
          error={!!fieldState.error}
        >
          <InputLabel>{label}</InputLabel>
          <Select {...field} label={label} fullWidth>
            {options?.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                disabled={option.disabled ?? false}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{fieldState.error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};
