import {
  InputCheckbox,
  InputDate,
  InputStudentDocument,
  InputFaculty,
  InputNumber,
  InputProgram,
  InputRadio,
  InputRegion,
  InputSelect,
  InputSpeciality,
  InputText,
  InputUniversity,
  InputFile,
  InputTextarea,
  InputPhoneNumber,
} from '../components';
import { InputType } from '../../../generated/graphql';
import { InputProps } from '../InputController';
import { ReactElement } from 'react';

export const INPUT_DICT: {
  [key in InputType]: (props: InputProps) => ReactElement;
} = {
  [InputType.Text]: InputText,
  [InputType.Select]: InputSelect,
  [InputType.Checkbox]: InputCheckbox,
  [InputType.Radio]: InputRadio,
  [InputType.Number]: InputNumber,
  [InputType.University]: InputUniversity,
  [InputType.Program]: InputProgram,
  [InputType.Faculty]: InputFaculty,
  [InputType.Speciality]: InputSpeciality,
  [InputType.Region]: InputRegion,
  [InputType.Date]: InputDate,
  [InputType.Document]: InputStudentDocument,
  [InputType.File]: InputFile,
  [InputType.Textarea]: InputTextarea,
  [InputType.PhoneNumber]: InputPhoneNumber,
};
