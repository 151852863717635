import client from '../../../../../config/apollo/client';
import {
  ApplicationFragment,
  GetApplicationsDocument,
  GetApplicationsQuery,
  GetApplicationsQueryVariables,
} from '../../../../../generated/graphql';

export const cacheApplicationsAdd = (application: ApplicationFragment) => {
  client.cache.updateQuery<GetApplicationsQuery, GetApplicationsQueryVariables>(
    {
      query: GetApplicationsDocument,
    },
    (applicationsData) => {
      if (!applicationsData) {
        return null;
      }
      return {
        studentGetApplications: [
          ...applicationsData.studentGetApplications,
          {
            id: application.id,
            stage: application.stage,
            createdAt: application.createdAt,
            service: application.service,
            __typename: 'Application',
          },
        ],
      };
    },
  );
};
