import { Box, Link, FormLabel, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { ProfilePhoneNumberDialog } from './ProfilePhoneNumberDialog';

export const ProfilePhoneNumber = ({
  phoneNumber,
}: {
  phoneNumber: string;
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <FormLabel component="legend">{t('profile.user.phoneNumber')}</FormLabel>
      <Stack direction="row" spacing={1}>
        <Typography>{phoneNumber}</Typography>
        <Link component="button" variant="body2" onClick={handleOpen}>
          Изменить
        </Link>
      </Stack>

      {open && (
        <ProfilePhoneNumberDialog
          onClose={handleClose}
          phoneNumber={phoneNumber}
        />
      )}
    </Box>
  );
};
