import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogActions,
  useMediaQuery,
  useTheme,
  IconButton,
  Stack,
  Button,
  Typography,
  Box,
} from '@mui/material';
import {
  GetApplicationDocument,
  GetApplicationQuery,
  GetApplicationQueryVariables,
  StageActionServiceDocumentFragment,
  StageTransitionFragment,
  useCreateApplicationDocumentMutation,
  useGetApplicationQuery,
  useGetSignedUrlForApplicationDocumentFileUploadLazyQuery,
} from '../../../../../../generated/graphql';
import { ApplicationDocumentFormNew } from './ApplicationDocumentFormNew';
import { Instructions } from './Instructions';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import { StageTransitionButton } from '../../../StageTransitionButton';

export const StageActionButtonDocumentUploadDialog = ({
  open,
  handleClose,
  applicationId,
  serviceDocument,
  transitions,
}: {
  open: boolean;
  handleClose: () => void;
  applicationId: string;
  serviceDocument: StageActionServiceDocumentFragment;
  transitions: StageTransitionFragment[];
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [uploading, setUploading] = useState<boolean>(false);
  const [createApplicationDocument, { loading }] =
    useCreateApplicationDocumentMutation({
      update(cache, result) {
        const newApplicationDocument =
          result.data?.studentCreateApplicationDocument;

        if (!newApplicationDocument) {
          throw Error('Something went wrong');
        }

        cache.updateQuery<GetApplicationQuery, GetApplicationQueryVariables>(
          {
            query: GetApplicationDocument,
            variables: {
              id: applicationId,
            },
          },
          (data) => {
            if (data !== null) {
              return {
                studentGetApplication: {
                  ...data.studentGetApplication,
                  documents: [
                    ...data.studentGetApplication.documents,
                    newApplicationDocument,
                  ],
                },
              };
            }
          },
        );
      },
    });
  const [getSignedUrls] =
    useGetSignedUrlForApplicationDocumentFileUploadLazyQuery();

  const { data } = useGetApplicationQuery({
    variables: {
      id: applicationId,
    },
  });

  if (!data) {
    throw Error('Cache error');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCreateApplicationDocument = async ({ file }: any) => {
    setUploading(true);
    const fileList = file as FileList;

    const { data } = await getSignedUrls({
      variables: {
        applicationId,
        serviceDocumentId: serviceDocument.id,
        fileName: Array.from(fileList)[0].name,
      },
    });

    if (!data) {
      throw Error('Something went wrong');
    }

    const { signedUrl, key } =
      data.studentGetSignedUrlForApplicationDocumentFileUpload;

    await fetch(signedUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': fileList[0].type,
      },
      body: fileList[0],
    }).finally(() => {
      setUploading(false);
    });

    createApplicationDocument({
      variables: {
        applicationId,
        serviceDocumentId: serviceDocument.id,
        file: {
          key,
          name: fileList[0].name,
          type: fileList[0].type,
        },
      },
    });
  };

  return (
    <Dialog
      open={open}
      fullScreen={isMobile}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle borderBottom="1px solid rgba(0, 0, 0, 0.12)">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6"> {serviceDocument.name}</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent
        sx={{
          px: 2,
        }}
      >
        <Box pt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <ApplicationDocumentFormNew
                loading={uploading || loading}
                handleSubmit={handleCreateApplicationDocument}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Instructions applicationData={data.studentGetApplication.data} />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Stack
          direction={isMobile ? 'column-reverse' : 'row'}
          spacing={1}
          width={isMobile ? '100%' : 'auto'}
        >
          {isMobile && (
            <Button
              onClick={handleClose}
              disabled={uploading || loading}
              variant="outlined"
            >
              Назад
            </Button>
          )}

          {transitions.map((transition) => (
            <StageTransitionButton
              key={transition.nextStageId}
              applicationId={applicationId}
              transition={transition}
              disabled={uploading || loading}
            />
          ))}

          <LoadingButton
            type="submit"
            form="APPLICATION_DOCUMENT_NEW"
            loading={uploading || loading}
            variant="contained"
          >
            Добавить
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
