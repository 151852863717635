import {
  StageActionFragment,
  StageActionType,
  StageTransitionFragment,
} from '../../../../generated/graphql';
import {
  StageActionButtonCorrection,
  StageActionButtonDocumentUpload,
  StageActionButtonSigning,
} from './components';

export const StageActionButton = ({
  applicationId,
  transitions,
  action,
}: {
  applicationId: string;
  transitions: StageTransitionFragment[];
  action: StageActionFragment;
}) => {
  const renderButton = () => {
    switch (action.type) {
      case StageActionType.Correction:
        return (
          <StageActionButtonCorrection
            applicationId={applicationId}
            transitions={transitions}
            button={action.button}
          />
        );
      case StageActionType.Signing:
        return (
          <StageActionButtonSigning
            applicationId={applicationId}
            button={action.button}
          />
        );
      case StageActionType.DocumentUpload:
        if (!action.serviceDocument) {
          throw Error('No serviceDocument');
        }
        return (
          <StageActionButtonDocumentUpload
            applicationId={applicationId}
            serviceDocument={action.serviceDocument}
            transitions={transitions}
            button={action.button}
          />
        );
      default:
        return null;
    }
  };

  return renderButton();
};
